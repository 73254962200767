import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/distylerie/1.png"
import img2 from "../../images/distylerie/2.png"
import img3 from "../../images/distylerie/3.png"
import img4 from "../../images/distylerie/4.png"
import img5 from "../../images/distylerie/5.png"




// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    distylerie is a branding and design agency, stuffed with brilliant designers and sharp strategic thinkers.
                    
                    <p/>
                    I wrote their website in English and German. Describing their methods was an enriching intellectual challenge, as working with them on any project always is.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                  distylerie ist eine Branding- und Designagentur, bewohnt von brillianten Designern und strategischen Denkern.
                  <p/>
                  Ich textete ihre Webseite auf Englisch und Deutsch. Ihre Methoden zu beschreiben war intellektuell bereichernd – wie immmer, wenn man mit distylerie zusammenarbeitet.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>


    <img src={img1}/>
    <img src={img2}/>
    <img src={img3}/>
    <img src={img4}/>
    <img src={img5}/>










      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
